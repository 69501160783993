import React from 'react'
import PropTypes from 'prop-types'

import SEO from '../../atoms/Seo'
import JsonLD from '../../atoms/JsonLD'
import Navbar from '../../organisms/Navbar'
import Footer from '../../organisms/NewFooter'
import ConfirmationModal from '../../atoms/ConfirmationModal'

const FormSuccessTemplate = ({ title, location, redirection, description, messageTitle, messageContent, messageSubContent, buttonLabel }) => {
  const linkedData = {
    '@context': 'http://schema.org',
    '@type': 'Project',
    name: 'Folhomee',
    description,
    parentOrganization: {
      '@type': 'Organization',
      name: 'Folhomee',
      location: {
        '@type': 'PostalAddress',
        streetAddress: '44 Rue du Chemin Vert',
        addressLocality: 'Paris',
        postalCode: '75011'
      }
    },
    keywords: 'Programme immobilier, Folhomee, expertise'
  }

  return (
    <>
      <SEO
        title={title}
        location={location}
        description={description}>
        <JsonLD>
          {linkedData}
        </JsonLD>
      </SEO>
      <Navbar />
      <ConfirmationModal
        show={true}
        redirection={redirection}
        buttonLabel={buttonLabel}
        messageTitle={messageTitle}
        messageContent={messageContent}
        messageSubContent={messageSubContent}
        updateShow={() => { }} />
      <Footer />
    </>
  )
}

FormSuccessTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  location: PropTypes.object,
  redirection: PropTypes.string,
  buttonLabel: PropTypes.string,
  messageTitle: PropTypes.string,
  messageContent: PropTypes.string,
  messageSubContent: PropTypes.string,
  description: PropTypes.string.isRequired
}

export default FormSuccessTemplate
