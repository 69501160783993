import React from 'react'

import FormSuccessTemplate from '../../../components/templates/FormSuccessTemplate'

const Success = () => {
  return (
    <FormSuccessTemplate
      title="Succès - demande acquisition"
      redirection='/'
      buttonLabel="Retour à l'accueil"
      description="Votre demande d'acquisition a bien été déposée sur notre plateforme Folhomee !" />
  )
}

export default Success
